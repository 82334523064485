
/* readability css */

:root {
  --bg-color: #ffffff;
  --font-color: #000000;
  /* highest contrast colors
  for light and dark themes */
  --red: #ec0000;
  --green: #008900;
  --blue: #5f5fff;
  --gray: #757575;
}

@media (prefers-color-scheme: dark) {
  :root {
      /* change to dark theme */
      --bg-color: #000000;
      --font-color: #ffffff;
  }
}

@media print {
  :root {
      /* change to dark theme */
      --bg-color: #FFFFFF;
      --font-color: #000000;
  }
}

*:disabled {
  cursor: not-allowed !important;
}

[href]:hover {
  text-decoration: none;
}


body {
  background: var(--bg-color);
  color: var(--font-color);
  /* most readable wed-safe font */
  font-family: Helvetica;
  /* 12pt is minimum */
  font-size: 12pt;
  /* experimental:
  highest quality images */
  image-rendering: high-quality;
  /* 1.5 is minimum */
  line-height: 1.5;
  /* center body in page */
  margin: auto;
  /* space inside body */
  padding: 0 1em;
  /* browser focuses on readability */
  text-rendering: optimizeLegibility;
}

html {
  /* better for jump scrolling */
  scroll-behavior: smooth;
}

iframe {
  /* common screen ratio */
  aspect-ratio: 3/2;
  /* keep from overflowing */
  width: 99%;
}

kbd {
  /* appear like a key */
  box-shadow: 1px 1px 2px 1px;
}

table {
  /* multiple borders merge */
  border-collapse: collapse;
  display: block;
}

td,
th {
  border: 1px solid;
  border-collapse: collapse;
}

.navbar {
  background-color: var(--bg-color) !important;
  color: var(--font-color);
}

/**/

.App {
  text-align: left;
}

.App-logo {
  height: 20px;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.navbar-light .navbar-brand h1.name, .navbar-light .navbar-brand h6.sub-title {
    color: --font-color !important;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand h1.name,
.navbar-light .navbar-brand h6.sub-title {
  color: --font-color !important;
}
.sub-title {
  font-size: 0.95em;
  margin-left: 8px;
}

.sideHeading {
  font-weight: bold;
  font-size: 1.05em;
  text-transform: uppercase;
}
.sideDegree {
  font-weight: bold;
  font-size: 0.85em;
  margin-bottom: 3px;
}
.sideSchool {
  font-weight: normal;
  font-size: 0.85em;
  margin-top: 5px;
  margin-bottom: 2px;
}
.sideSchoolLocation {
  font-weight: normal;
  font-style: italic;
  font-size: 0.8em;
  margin-top: 2px;
  margin-left: 12px;
}
.sidePoints {
  font-weight: normal;
  font-size: 0.8em;
}

.search-box {
  margin: 8px 12px;
}

.email-quote {
  margin-top: -18px;
  font-size: 0.9em;
}

.icon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  overflow: hidden;
}

.icon-sm {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: inline-block; 
}

.icon-sm path {
  fill: var(--font-color) !important;
} 

.phone-icon, .email-icon, .print-icon {
  position: relative;
}
.print-icon {
  margin-left: 1em;
}
.icon-svg {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
}

a.tele-link {
  text-decoration: none;
  cursor: pointer;
  color: var(--font-color) !important;
}

.peek-at-source {
  margin: 28px 20px 12px 12px;
}

.peek-at-source a {
  font-size: 0.6em;
  text-decoration: none;
  color: #444;
}

.peek-at-source img {
  height: 1.2em;
}
  
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s linear  ;
  }
  .icon:hover {
    animation: Heart-Beat infinite 3s linear  ;
  }
  .peek-at-source img {
    animation: App-logo-float infinite 3s linear  ;
  }
}

@keyframes Heart-Beat
{
  0%
  {
    transform: scale( 1 );
  }
  20%
  {
    transform: scale( 1.25 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1.1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( 1 );
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(-3px);
  }
}
.contact {
  flex-grow: 0 ;
}

.made-with {
  font-size: 0.7em;
  color: rgb(157, 157, 157);
}

@media print {
  .no-print {
    display: none;
    visibility: hidden;
  }

  .contact {
    position: absolute;
    right: 12px;
    top: 10px;
  }  
}